<script lang="ts" setup>

</script>

<template>
  <v-app class="h-screen bg-gradiant">
    <AdminSidebar />
    <UiMainHeader />
    <v-main class="overflow-y-auto" scrollable>
      <!-- <v-container class="py-0 pa-4 fill-height"> -->
      <slot />
    </v-main>
    <v-snackbar
      v-model="isNewerVersion"
      color="accent"
      multi-line
      rounded="xl"
    >
      <template #actions>
        <a class="mx-2" :href="$route.fullPath">
          <!-- <v-btn icon variant="text" @click="$route.fullPath"> -->
          <Icon name="tabler:refresh" size="32" />
          <!-- </v-btn> -->
        </a>
      </template>
      Eine neue Version ist verfügbar, um den vollen Funktionsumfang zu nutzen, aktualisiere bitte die Seite.
    </v-snackbar>
  </v-app>
</template>
